<template>
  <div>
    <BaseModal
      :name="idModal"
      :id="idModal"
      :idModal="idModal"
      @shown="modalShow"
      @hidden="modalHide"
      :title="this.edit.id ? 'Atualizar integração' : 'Cadastrar integração'"
    >
      <div class="container">
        <div class="row px-4" v-if="permissionOfGetFormFb">
          <div class="col-12">
            <label class="typo__label title">Fanpage</label>
            <input
              type="text"
              class="form-control w-100 input-name-fanpage"
              disabled
              :placeholder="fanpageName"
            />
          </div>

          <div class="col-12">
            <label class="typo__label title">Formulário do Facebook</label>
            <multiselect
              v-model="formFbSelected"
              :options="formFb"
              deselectLabel=""
              selectLabel=""
              tag-placeholder=""
              selectedLabel="Selecionado"
              :placeholder="nameFormFb"
              label="name"
              track-by="name"
              :disabled="disabledSelectFormFb"
              :showNoResults="false"
            ></multiselect>
          </div>
          <div class="col-12">
            <label class="typo__label title">Meus formulários</label>
            <BaseSelect
              id="ajax"
              :selectModel="formGSelected"
              placeholder="Selecione o formulário"
              track-by="id"
              trackname="title"
              :multiple="false"
              :array="formGDigital"
              selectLabel=""
              deselectLabel=""
              @callback="getForms($event)"
              @change="formGSelected = $event"
              :searchable="true"
              :watch="true"
              ><span slot="noResult">Nenhum formulário encontrado.</span>
            </BaseSelect>
          </div>
        </div>
        <UpdateConfigurationFanpage
          v-else
          @loged="modalShow"
          :fanpageName="fanpageName"
          :idModal="idModal"
        />
      </div>

      <template v-slot:footer>
        <BaseButton variant="primary" @click="integration" :disabled="btnDisabled" class="w-100" v-if="permissionOfGetFormFb">{{ nameBtn }}</BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import UpdateConfigurationFanpage from "./UpdateConfigurationFanpage.vue";

import LeadAdService from "@/services/resources/LeadAdService.js";
import FormService from "@/services/resources/FormService.js";
import LeadAdFormService from "@/services/resources/LeadAdFormService.js";
import leadgenForIntegration from "@/services/resources/leadgenForIntegration.js";

import { mapMutations } from "vuex";

const leadgenForIntegrationService = leadgenForIntegration.build();
const LeadAdFormApiService = LeadAdFormService.build();
const FormApiService = FormService.build();
const LeadAdApiService = LeadAdService.build();

export default {
  name: "ModalIntegration",
  props: {
    idModal: {
      required: true,
    },
    fanpageName: {
      required: true,
    },
    edit: {
      type: Object,
    },
  },
  components: {
    Multiselect,
    UpdateConfigurationFanpage,
  },
  data: () => ({
    render: false,
    stops: [],
    nameFormFb: "Formulário do Facebook",
    nameFormGDigital: "Meus formulários",
    optionsLeagensEncontrado: [
      { code: true, name: "Adicionar na integração" },
      { code: true, name: "Não adicionar na integração" },
    ],
    leagensEncontradoSelected: null,
    formFb: [],
    formGDigital: [],
    formGSelected: null,
    formFbSelected: null,
    disableBtn: false,
    permissionOfGetFormFb: true,
    leadgensEncontrado: false,
  }),
  computed: {
    getId: function () {
      return this.$route.params.id;
    },
    btnDisabled() {
      if (this.disableBtn) {
        return true;
      } else if (this.formFbSelected && this.formGSelected) {
        return false;
      } else if (this.formGSelected && this.edit.id) {
        return false;
      }
      return true;
    },
    nameBtn() {
      return this.edit.id ? "Atualizar integração" : "Adicionar integração";
    },
    disabledSelectFormFb() {
      if (this.edit.id) {
        return true;
      }
      return this.formFb.length === 0;
    },
    getMessageUpdateOrCreate() {
      if (this.edit.id) {
        return "Formulário atualizado com sucesso!";
      }
      return "Formulário adicionado com sucesso!";
    },
  },
  methods: {
    async modalShow() {
      this.permissionOfGetFormFb = true;
      this.formGSelected = null;
      this.formFbSelected = null;
      if (!this.edit.id) {
        await this.getFormFacebook();
      } else {
        this.formGSelected = {
          id: this.edit.form_g_digital_id,
          title: this.edit.form_g_digital,
        };
        this.formFbSelected = {
          code: this.edit.fb_form_id,
          name: this.edit.fb_form_title,
        };
      }
    },
    modalHide() {
      this.$emit("modalHide");
    },
    getForms(page = 1) {
      return new Promise((resolve, reject) => {
        if (this.render == true) {
          resolve(); // Resolve imediatamente se 'tag' estiver na lista de paradas
        }
        if (this.render == false) {
          if (this.stops.includes("form")) {
            resolve(); // Resolve imediatamente se 'form' estiver na lista de paradas
          } else {
            var data = {
              id: `?page=${page}&global_scope=true&status=active`,
            };

            FormApiService.read(data)
              .then((resp) => {
                if (resp.data.length < resp.per_page) {
                  this.stops.push("form");
                }
                this.formGDigital = this.formGDigital.concat(resp.data);
                resolve(); // Resolva após o término bem-sucedido
              })
              .catch(reject); // Rejeite em caso de erro
          }
        }
      });
    },
    async getFormFacebook() {
      try {
        this.formFb = [];
        let forms = await LeadAdApiService.read(
          "/fanpage/" + this.getId + "/facebook/form"
        );

        if (forms.code && forms.code === 200) {
          this.permissionOfGetFormFb = false;
        }
        if (forms.error) {
          this.$bvToast.toast(forms.error, {
            title: "LeadAds",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        } else {
          if (!forms.data[0]) {
            this.$bvToast.toast(
              "Não foi localizado formulários de sua fanpage para integração.",
              {
                title: "LeadAds",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            return;
          }
          for (const form of forms.data) {
            this.formFb.push({ code: form.id, name: form.name });
          }
        }
      } catch (e) {
        this.$bvToast.toast("Falha ao buscar formulários do facebook", {
          title: "LeadAds",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
    },
    async integration() {
      try {
        this.disableBtn = true;
        const data = {
          fanpage_id: this.getId,
          fb_form_id: this.formFbSelected.code,
          fb_form_title: this.formFbSelected.name,
          form_id: +this.formGSelected.id,
        };
        let formIntegration = null;

        if (this.edit.id) {
          formIntegration = await LeadAdFormApiService.update(
            Object.assign(data, { id: this.edit.id })
          );
        } else {
          formIntegration = await LeadAdFormApiService.create(data);
        }
        if (formIntegration.hasLeadads) {
          this.hasLeadads(formIntegration);
        }

        if (!formIntegration.error) {
          this.$bvToast.toast("Integração criada com sucesso!", {
            title: "LeadAds",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide(this.idModal);
          this.$emit("integration", this.edit.id ?? false);
          this.disableBtn = false;
          return;
        }
        this.$bvToast.toast(formIntegration.error, {
          title: "LeadAds",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } catch (e) {
        // console.log(e);
        this.$bvToast.toast("Desculpe falha ao salvar Formulário", {
          title: "LeadAds",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
      this.disableBtn = false;
    },
    async hasLeadads(data) {
      let res = await this.$swal({
        title: "",
        icon: "info",
        html: "Desejá adicionar lead capturadas pelo sistema antes da integração?",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Adicionar",
        confirmButtonAriaLabel: "Thumbs up, great!",
        cancelButtonText: "Cancelar",
        cancelButtonAriaLabel: "Thumbs down",
        confirmButtonColor: "var(--greenn)",
      });

      if (res.isConfirmed) {
        let leadgens = await leadgenForIntegrationService.update({
          id: data.id,
        });
        if (leadgens.error) {
          this.$bvToast.toast(
            "Desculpe falha ao adicionar leads já cadastradas",
            {
              title: "LeadAds",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          return;
        }

        this.$bvToast.toast("Leads atualizado com sucesso.", {
          title: "LeadAds",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });

        this.$emit("integration", false);
      }
    },
  },
};
</script>

<style>
.multiselect__placeholder {
  color: #000;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: none;
}

.multiselect--disabled > .multiselect__tags,
.multiselect--disabled > .multiselect__tags > .multiselect__single {
  background-color: #d2d9e0 !important;
  border: 1px solid #ced4da;
}

#icon-close {
  cursor: pointer;
}

.input-name-fanpage {
  width: 100%;
  height: 42px;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #000;
}

.icon-fanpage {
  width: 45px;
  height: 45px;
}

.btn-action-integration {
  width: 100%;
  height: 50px;
  background: var(--gray01);
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

#title,
.title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 20px;
  color: #000000;
}

.sub-title {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #8c8a97;
}

input {
  height: 50px;
}

.btn-action-integration:hover {
  color: #ffffff;
}

/*
  
*/
</style>
